<script>
  import api from "../../api";
  import global from "../../assets/js/global";
  import BlogSectionList from "./BlogSectionList.vue";
  import VueSlickCarousel from "vue-slick-carousel";
  export default {
    components: { BlogSectionList, VueSlickCarousel },
    data: () => {
      return {
        isLoading: true,
        post: null,
        dotCount: 5,
        currentPage: 0,
        iconUrl: "https://new-web-site-bucket.s3.amazonaws.com/blogIcons/authorIcon.png",
        settings: {
          arrows: true,
          dots: true,
          dotsNumber: 3,
          slidesToShow: 1,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 1250,
              settings: {
                arrows: true,
                dots: false,
              },
            },
          ],
        },
      };
    },
    metaInfo() {
      let description = "Descubre los últimos consejos y tendencias en modelaje en el blog de Models1A.";
      let title = "Models1A Blog para Modelos Webcams";

      let canonicalURL = "";

      if (this.$route && this.$router) {
        canonicalURL = window.location.origin + this.$router.resolve(this.$route.fullPath.split("#")[0])?.href;
      }

      if (this.post) {
        title = this.post?.title;

        const htmlTxt = this.post.describe;
        const textWithoutTags = htmlTxt.replace(/<[^>]*>/g, "");
        const cleanText = textWithoutTags.replace(/\s+/g, " ").trim();
        const metaDescription = cleanText.substring(0, 190);

        description = metaDescription;
      }

      return {
        title,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description,
          },
          {
            vmid: "robots",
            name: "robots",
            content: "index, follow",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: description,
          },
        ],
        link: [
          {
            vmid: "canonical",
            rel: "canonical",
            href: canonicalURL,
          },
        ],
      };
    },
    methods: {
      getData: async function () {
        let id = this.$route.query.id;
        let response = await api.get(`${process.env.VUE_APP_API_HOST}blog/articles?id=${id}`);
        this.post = response.data;
        this.$meta().refresh();
      },
      dateFormat: (date) => {
        return global.dateLocaleFormat(date);
      },
      withBrTags: function (text) {
        return text.replace(/(\\r)*\\n/g, "<br>");
      },
      removeHTMLEntities: (text) => {
        return global.removeHTMLEntities(text);
      },
      handleMediaLoad() {
        this.isLoading = false;
      },
    },
    computed: {
      initData: async function () {
        await this.getData();
      },
      href: function () {
        return window.location.origin + this.router.fullPath.split("#")?.[0];
      },
    },
  };
</script>
<template>
  <section v-bind="initData">
    <div class="post-section section" id="post" v-if="post">
      <div class="post-section-wrap">
        <div class="post-section-main">
          <div class="post-section-post">
            <div class="post-section-title">{{ post.title }}</div>
            <div class="post-section-content">
              <div class="post-section-picture">
                <div class="post-section-loading" :class="{ loading: isLoading }" v-show="isLoading"></div>
                <div v-show="!isLoading">
                  <VueSlickCarousel v-bind="settings" ref="slider" class="post-section-picture-carousel">
                    <div v-for="(slide, idx) in post.multimedia" :key="idx" class="slide">
                      <img :src="slide.file" :alt="`article-image-${idx}`" v-if="slide.typeFile == 'image'" @load="handleMediaLoad" />
                      <video :src="slide.file" v-if="slide.typeFile == 'video'" controls muted autoplay @loadeddata="handleMediaLoad"></video>
                    </div>
                  </VueSlickCarousel>
                </div>

                <div class="post-section-author">
                  <div class="post-section-author-user">
                    <div class="post-section-author-picture"><img :src="iconUrl" alt="icon" class="post-section-author-icon" /></div>
                    <div>
                      <div class="post-section-author-user-name">{{ post.authorName }}</div>
                      <div class="post-section-author-user-label">{{ post.authorLabel }}</div>
                    </div>
                  </div>
                  <div class="post-section-author-date">
                    <span>{{ dateFormat(post.date) }}</span>
                  </div>
                </div>
              </div>
              <div class="post-section-text" v-html="removeHTMLEntities(post.describe)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="post-section-interest">
        <div class="post-section-interest-content">
          <div class="post-section-main">
            <div class="post-section-interest-title">{{ post.relArtLabel }}</div>
            <div class="post-section-interest-list">
              <BlogSectionList :items="post.relatedArticles"></BlogSectionList>
            </div>
            <div class="post-section-interest-more"><router-link to="/blog#blog">Ver más</router-link></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  $textColor: #5b5957;

  .post-section {
    margin-top: 75px;
    ul,
    ol {
      padding-left: 25px;
    }
    ul li {
      list-style-type: disc;
    }
    ol li {
      list-style-type: decimal;
    }
    &-main {
      max-width: 1024px;
      margin: 0 auto;
    }
    &-wrap {
      padding: $mpadding;
    }
    &-picture {
      border-radius: 12px;
    }
    &-picture-carousel {
      margin-bottom: 60px;
      video,
      img {
        max-width: 100%;
        height: 600px;
        border-radius: 12px;
      }

      .slide {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      .slick-prev,
      .slick-next {
        z-index: 1;
        height: 100%;
        width: 60px;
        height: 70px;
      }
      .slick-prev::before,
      .slick-next::before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
      }
      .slick-prev {
        left: -70px;
        background: url("https://new-web-site-bucket.s3.amazonaws.com/website/gallery/Component%2046.svg") no-repeat center;
      }
      .slick-next {
        right: -70px;
        background: url("https://new-web-site-bucket.s3.amazonaws.com/website/gallery/Component%2045.svg") no-repeat center;
      }
      .slick-dots {
        margin-top: 10px;
        top: 100%;
      }

      .slick-dots li {
        margin: 0 5px !important;
      }
      .slick-dots li button {
        font-size: 15px;
        color: transparent;
        width: 1px;
        height: 5px;
        border-radius: 10px;
        background-color: #d9d9d980;
      }

      .slick-dots li.slick-active button {
        background-color: $primary_color;
      }

      .slick-dots li button:before {
        color: transparent;
        opacity: 0;
      }
    }
    &-title {
      color: $primary_color;
      text-align: center;
      font-size: 32px;
      font-family: $sec_font;
      margin-bottom: $mpadding;
    }
    &-content {
      display: block;
    }

    &-image {
      width: 100%;
      height: 206px;
      border-radius: 12px;
      background-color: $alto;
      border-radius: $mradius;
      background-image: var(--postImage);
      background-size: cover;
      background-position: center;
      margin-bottom: $mpadding;
    }
    &-video {
      margin-bottom: $mpadding;
      text-align: center;
      video {
        max-width: 100%;
        border-radius: 12px;
      }
    }
    &-author {
      display: flex;
      justify-content: space-between;
      margin-bottom: $mpadding;
      &-user {
        @include Flex();
        &-name {
          font-family: $sec_font;
          color: $primary_color;
        }
        &-label {
          color: $textColor;
          font-size: 12px;
        }
      }
      &-picture {
        @include Flex();
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $primary_color;
        margin-right: $mpadding/1.5;
      }
      &-icon {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
      &-date {
        color: $textColor;
        font-size: 12px;
      }
    }
    &-text {
      div,
      span,
      p {
        font-family: $primary_font !important;
        font-size: 16px !important;
      }
      color: $textColor;
      ol {
        margin: 20px 0 20px 20px;
      }
      p {
        margin: 0 0 $mpadding 0;
      }
      a:hover {
        color: #0056b3;
        text-decoration: underline;
      }
      a {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
      }
      img,
      video {
        max-width: 100%;
      }
    }

    &-interest {
      &-title {
        text-align: center;
        padding: $mpadding;
        font-size: 32px;
        color: $primary_color;
        font-family: $sec_font;
      }
      &-more {
        text-align: center;
        color: $primary_color;
        padding: $mpadding;
        a:hover {
          border-bottom: solid 1px;
        }
      }
    }

    &-loading {
      width: 100%;
      height: 650px;
      display: flex;
      align-items: center;
      justify-content: start;
      position: relative;
    }

    @media (min-width: $tablet_width) {
      &-title {
        font-size: 42px;
        margin-bottom: $mpadding * 2;
      }

      &-image {
        width: 100%;
        height: 450px;
      }
    }
    @media (max-width: 700px) {
      .post-section-picture-carousel {
        .slick-prev {
          top: 100%;
          left: 100px;
        }
        .slick-next {
          top: 100%;
          right: 100px;
        }
        .slick-arrow {
          margin: 20px 0;
        }
      }
      .post-section-picture-carousel {
        video,
        img {
          height: 230px;
        }
      }
      .post-section-loading {
        height: 230px;
      }
    }
    @media (max-width: 480px) {
      .post-section-picture-carousel {
        margin-bottom: 70px;
        .slick-arrow {
          margin: 30px 0;
        }
      }
    }
  }
</style>
